import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import image from "./product_images/cayugablue.png";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={"5xl"} py={10} boxShadow={"lg"} borderRadius={"lg"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Heading>Cayuga Blue</Heading>
          <Text
            textTransform={"uppercase"}
            color={"black"}
            fontWeight={600}
            fontSize={"sm"}
            bgGradient="linear(to-l, gray.300, gray.100, gray.200, gray.100)"
            p={3}
            alignSelf={"flex-start"}
            rounded={"lg"}
          >
            Pasteurized Goat Milk Blue Cheese
          </Text>

          <Text color={"gray.500"} fontSize={"lg"}>
            Cayuga Blue is a rare, natural-rind goat milk blue cheese with
            marbled blue mold as deep in color as the lake it was named after.
            It offers rich earthy and mushroom flavors with a hint of nutty
            sweetness. It pairs perfectly with off-dry Riesling, raspberry jam,
            and chocolate. Ideal for dishes featuring beef or mushrooms, its
            crumbly texture also makes it perfect for salads.
          </Text>
          <Stack
            spacing={2}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature text={"Shelf Life: 4 months"} />
            <Feature text={"Aged: 45 days"} />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={image}
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
