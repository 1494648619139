import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
  } from "@chakra-ui/react";
  import { ReactElement } from "react";
  import image from "./product_images/feta.png";
  
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text }: FeatureProps) => {
    return (
      <Stack direction={"row"} align={"center"}>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function SplitWithImage() {
    return (
      <Container maxW={"5xl"} py={10} boxShadow={"lg"} borderRadius={"lg"}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Heading>Feta</Heading>
            <Text
            textTransform={"uppercase"}
            color={"black"}
            fontWeight={600}
            fontSize={"sm"}
            bgGradient="linear(to-l, gray.300, gray.100, gray.200, gray.100)"
            p={3}
            alignSelf={"flex-start"}
            rounded={"lg"}
          >
              Greek Style Salt Brind Pasteurized Goat Milk Cheese
            </Text>
  
            <Text color={"gray.500"} fontSize={"lg"}>
            Traditional Greek-style Feta is aged for a minimum of two weeks in salt brine, resulting
in a crumbly texture with a salty kick. In addition to its traditional use in salads, Feta
pairs beautifully with pork.
            </Text>
            <Stack
              spacing={2}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }
            >
              <Feature text={"Shelf Life: 180 Days"} />
              {/* <Feature text={"Aged: NA"} /> */}
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={image}
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Container>
    );
  }
  