import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import image from "./product_images/blueyonder.png";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={"5xl"} py={10} boxShadow={"lg"} borderRadius={"lg"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Heading>Blue Yonder</Heading>
          <Text
            textTransform={"uppercase"}
            color={"black"}
            fontWeight={600}
            fontSize={"sm"}
            bgGradient="linear(to-l, gray.300, gray.100, gray.200, gray.100)"
            p={3}
            alignSelf={"flex-start"}
            rounded={"lg"}
          >
            Pasteurized Cow Milk Blue Cheese
          </Text>

          <Text color={"gray.500"} fontSize={"lg"}>
            Blue Yonder is a natural rind cow milk blue cheese. Aged for at
            least sixty days, it develops a pungent, slightly spicy flavor
            contrasted by a rich, fudge-like texture from the high butterfat
            content. This is a great melting blue cheese, perfect for use in
            burgers or blue cheese pasta sauce. Rich and intense, Blue Yonder is
            a blue cheese lover’s delight, pairing well with wines, honey, and
            red meats.
          </Text>
          <Stack
            spacing={2}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature text={"Shelf Life: 6 months"} />
            <Feature text={"Aged: 60 days"} />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={image}
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
