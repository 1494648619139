import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import image from "./product_images/fingerlake.png";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={"5xl"} py={10} boxShadow={"lg"} borderRadius={"lg"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Heading>Finger Lakes Gold</Heading>
          <Text
            textTransform={"uppercase"}
            color={"black"}
            fontWeight={600}
            fontSize={"sm"}
            bgGradient="linear(to-l, gray.300, gray.100, gray.200, gray.100)"
            p={3}
            alignSelf={"flex-start"}
            rounded={"lg"}
          >
            Pasteurized Goat Milk Hard Aged Cheese
          </Text>

          <Text color={"gray.500"} fontSize={"lg"}>
            Aged for at least three months, Finger Lakes Gold cheese offers a
            mellow, nutty flavor with a firm texture and golden-yellow rind.
            Grassy notes and a burnt sugar finish characterize its taste,
            reflecting the Finger Lakes terroir. It's a unique addition to
            any cheese board, complementing cured meats, jams, or fruit. It also
            pairs well with dry Rieslings, crisp lagers, light ales, and tart
            jams.
          </Text>
          <Stack
            spacing={2}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature text={"Shelf Life: 6 months"} />
            <Feature text={"Aged: at least 90 days"} />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={image}
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
